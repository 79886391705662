import React, { ReactElement } from 'react';
import { Container } from 'react-bootstrap';
import { Layout } from '../components/common';
import ContentImageRow from '../components/common/ContentImageRow';
import BannerSection from '../components/common/contentSections/BannerSection';
import ContentHero from '../components/common/contentSections/ContentHero';
import HeadingSection from '../components/common/contentSections/HeadingSection';
import TypesSection from '../components/common/contentSections/TypesSection';
import ImageContentRow from '../components/common/ImageContentRow';
import { ContentData } from '../utils/entities';

const ClockerContentData: ContentData[] = [
    {
        id: 1,
        title: `Manage productivity`,
        desc: `Manage and track the productivity speed of every employee. Get real time reports on members of staff working hours across projects, clock in time and clock out time with a simple time tracker and timesheet at any location.`,
        imageUrl: `images/manage-productivity.svg`,
    },
    {
        id: 2,
        title: `One mobile access`,
        desc: `Our clocker app works with all types of smartphone devices and allows employees in remote locations to clock in to register their working hours/attendance using a single device`,
        imageUrl: `images/mobile-access@3x.png`,
    },
    {
        id: 3,
        title: `Reporting`,
        desc: `Get real time reports on members of staff as admin can filter through employees clock in time, attendance and office login location. Admin can customize the report, share with others and export as PDF, Excel and CSV.`,
        imageUrl: `images/reporting.svg`,
    },
];

const Clocker = (): ReactElement => (
    <Layout>
        <Container>
            <ContentHero
                heading="Clocker for 
                Employees"
                desc="The one stop innovative clock-in system for businesses to track and manage employees attendance in any work location at any time. "
                buttonText="Try StackIAM"
                imageUrl="/images/clocker.svg"
            />
            <TypesSection
                heading="Employees Clocker Types"
                subheadingSection1="Web Clocker"
                subheadingSection2="Mobile Application Clocker"
                descSection1="Easily connect with partners that have their own IDP or access management deployment; federate using SAML or OIDC."
                descSection2="Connect your customer's LDAP or Active Directory; synchronize user accounts to Universal Directory."
                imageUrlSection1="images/workspace@3x.png"
                imageUrlSection2="images/mobile@3x.png"
            />
            <HeadingSection
                heading="Features"
                subheading="Clocker for Employees"
                desc="Whether you're looking for a two-factor authentication solution to meet compliance targets, or building a full MFA security framework, Okta is the adaptive multi-factor authentication vendor that has you covered.
                Implement a variety of different factors for authentication across usability and assurance levels:"
            />
            {ClockerContentData.map((item) => {
                if (item.id % 2 === 0) {
                    return <ContentImageRow key={item.id} {...item} />;
                } else {
                    return <ImageContentRow key={item.id} {...item} />;
                }
            })}
            <BannerSection />
        </Container>
    </Layout>
);

export default Clocker;
